@import "variables";

// Import styles
@import "@coreui/coreui/scss/coreui";

// Import coreui free icons
@import "@coreui/icons/css/free.min.css";

// Vendors
@import "simplebar";

// Custom styles for this theme
@import "theme";

// If you want to add custom CSS you can put it here
@import "custom";


@import 'select2/dist/css/select2.css';

@import "jquery-confirm/css/jquery-confirm.css";
